import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import { db, storage } from '../firebase.config';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { updateDoc, doc, getDoc } from 'firebase/firestore'; // Pour récupérer et mettre à jour le produit
import { useNavigate, useParams } from 'react-router-dom';
import { serverTimestamp } from 'firebase/firestore';

const EditProducts = () => {
    const { commandeId } = useParams();
    const [productData, setProductData] = useState({});
    const [enterTitle, setEnterTitle] = useState('');
    const [enterShortDesc, setEnterSortDes] = useState('');
    const [enterCategory, setEnterCategory] = useState('');
    const [enterNCategory, setEnterNCategory] = useState('');
    const [enterPrice, setEnterPrice] = useState('');
    const [enterAPrice, setEnterAPrice] = useState('');
    const [enterGros, setEnterGros] = useState('');
    const [enterQty, setEnterQty] = useState('');
    const [enterProductImg, setEnterProductImg] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProduct = async () => {
            const docRef = doc(db, 'products', commandeId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setProductData(docSnap.data());
                setEnterTitle(docSnap.data().productName);
                setEnterSortDes(docSnap.data().shortDesc);
                setEnterCategory(docSnap.data().category);
                setEnterNCategory(docSnap.data().arrivage);
                setEnterPrice(docSnap.data().price);
                setEnterAPrice(docSnap.data().aprice);
                setEnterGros(docSnap.data().gros);
                setEnterQty(docSnap.data().qty);
            } else {
                toast.error('Produit introuvable');
                navigate('/dashboard/all-products');
            }
        };
        fetchProduct();
    }, [commandeId, navigate]);

    const editProduct = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const docRef = doc(db, 'products', commandeId);
            await updateDoc(docRef, {
                productName: enterTitle,
                shortDesc: enterShortDesc,
                category: enterCategory,
                arrivage: enterNCategory,
                price: enterPrice,
                aprice: enterAPrice,
                gros: enterGros,
                qty: enterQty,
                timestamp: serverTimestamp(),
            });

            toast.success('Produit modifié avec succès');
            navigate('/dashboard/all-products');
        } catch (error) {
            toast.error('Erreur lors de la modification du produit');
        }

        setLoading(false);
    };

    return (
        <section>
            <Container>
                <Row>
                    <Col lg='12'>
                        {loading ? (
                            <h4 className='py-5'>Loading........</h4>
                        ) : (
                            <>
                                <h4 className='mb-5 mt-5'>Modifier produit</h4>

                                <Form onSubmit={editProduct}>
                                    <FormGroup className="form__group w-50">
                                        <span>Nom du Produit</span>
                                        <input
                                            type="text"
                                            placeholder='Nom du produit'
                                            value={enterTitle}
                                            onChange={e => setEnterTitle(e.target.value)}
                                            required
                                        />
                                    </FormGroup>

                                    <FormGroup className="form__group w-50">
                                        <span>Prix</span>
                                        <input
                                            type="number"
                                            placeholder='CFA'
                                            value={enterPrice}
                                            onChange={e => setEnterPrice(e.target.value)}
                                            required
                                        />
                                    </FormGroup>

                                    <FormGroup className="form__group w-50">
                                        <span>Ancien Prix</span>
                                        <input
                                            type="number"
                                            placeholder='CFA'
                                            value={enterAPrice}
                                            onChange={e => setEnterAPrice(e.target.value)}
                                        />
                                    </FormGroup>

                                    <div className='d-flex align-items-centerb justify-content-between gap-5 '>

                                        <FormGroup className="form__group w-50">
                                            <span>Categories</span>
                                            <select
                                                className='w-100 p-2'
                                                value={enterCategory}
                                                onChange={e => setEnterCategory(e.target.value)}   >
                                                <option ></option>
                                                <option value="t-shirt">T-Shirt</option>
                                                <option value="pulls">Pulls</option>
                                                <option value="chemise">Chemise</option>
                                                <option value="short">Short</option>
                                                <option value="ensemble">Ensemble</option>
                                                <option value="pantalon">Pantalon</option>
                                                <option value="sacs">Sacs</option>
                                                <option value="chaussure">Chaussure</option>
                                                <option value="tapete">Tapete</option>
                                                <option value="casquette">Casquette</option>
                                                <option value="friperie">Friperie</option>
                                                <option value="electronique">Electronique</option>
                                            </select>
                                        </FormGroup>

                                        <FormGroup className="form__group w-50">
                                            <span>Nouvelle Arrivage</span>
                                            <select
                                                className='w-100 p-2'
                                                value={enterNCategory}
                                                onChange={e => setEnterNCategory(e.target.value)}   >
                                                <option ></option>
                                                <option value="t-shirt">T-Shirt</option>
                                                <option value="pulls">Pulls</option>
                                                <option value="chemise">Chemise</option>
                                                <option value="short">Short</option>
                                                <option value="ensemble">Ensemble</option>
                                                <option value="pantalon">Pantalon</option>
                                                <option value="sacs">Sacs</option>
                                                <option value="chaussure">Chaussure</option>
                                                <option value="casquette">Casquette</option>
                                                <option value="friperie">Friperie</option>
                                                <option value="electronique">Electronique</option>
                                                <option value="tapete">Tapete</option>
                                            </select>
                                        </FormGroup>

                                    </div>

                                    <FormGroup className="form__group w-50">
                                        <span>Détails</span>
                                        <textarea
                                            placeholder='Détails...'
                                            value={enterShortDesc}
                                            onChange={e => setEnterSortDes(e.target.value)}
                                            required
                                        />
                                    </FormGroup>

                                    <button className="buy__btn" type='submit'>
                                        Modifier
                                    </button>
                                </Form>
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default EditProducts;
