import React from 'react';
import "../../styles/product-card.css";
import { motion } from 'framer-motion';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { cartActions } from '../../redux/slices/cartSlice';


const ProductsCard = ({ item }) => {

  const dispatch = useDispatch();

  const handleLink = () => {
    // Recharge la page lorsque le lien est cliqué
    window.location.href = `/shop/${item.id}`;
  };

  
const addToCart = () => {
  dispatch(cartActions.addItem({
    id: item.id,
    productName: item.productName,
    price: item.price,
    aprice: item.aprice,
    imgUrl: item.imgUrl,
    category: item.category,
    shortDesc : item.shortDesc,
  }));


  toast.success('Product ajouter avec success')
};


  return (
    <Col lg='3' md='4' className="mb-3" >
      <div className="card ">
        <div className="m-2 p-2 " >
          <Link onClick={handleLink}>
            <motion.img whileHover={{ scale: 0.9 }} src={item.imgUrl} alt="fluid" />
          </Link>
        </div>
        <div className="card-details p-2">
          <p className='nom'>{item.productName}</p>
          <p className="my-1"><span className="price1">{item.price} Fcfa </span>&nbsp; <strike className="price">{item.aprice} Fcfa</strike></p>
          <div className="d-flex align-items-center justify-content-around  gap-4">
          <Link  onClick={handleLink}  className='view-button '>
              Detail
            </Link>
            <div className="col-6 d-flex align-items-center justify-content-around ">
            <motion.button className="add-btn "
             whileTap={{ scale: 1.2 }}
            onClick={addToCart}
            >
              Ajouter
              {item.quantity} 
            </motion.button>
          </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ProductsCard;
