import React, { useState } from 'react';
import { Container, Row, Col, Form, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import { db, storage } from '../firebase.config';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';
import { serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const AddProducts = () => {
    const [enterTitle, setEnterTitle] = useState('');
    const [enterShortDesc, setEnterSortDes] = useState('');
    const [enterCategory, setEnterCategory] = useState('');
    const [enterNCategory, setEnterNCategory] = useState('');
    const [enterPrice, setEnterPrice] = useState('');
    const [enterAPrice, setEnterAPrice] = useState('');
    const [enterGros, setEnterGros] = useState('');
    const [enterQty, setEnterQty] = useState('');
    const [enterProductImg, setEnterProductImg] = useState(null);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false); // Nouvel état pour suivre si le formulaire a été soumis
    const navigate = useNavigate();

    const addProduct = async (e) => {
        e.preventDefault();

        // Vérifier si le formulaire a déjà été soumis
        if (submitted) {
            return;
        }

        // Activer le chargement et marquer le formulaire comme soumis
        setLoading(true);
        setSubmitted(true);

        // Ajouter le produit dans la base de données
        try {
            const docRef = await collection(db, 'products');

            const storageRef = ref(storage, `productImg/${Date.now() + enterProductImg.name}`);
            const uploadTask = uploadBytesResumable(storageRef, enterProductImg);

            uploadTask.on(() => {
                toast.error('Image non enregistrée');
            }, () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                    await addDoc(docRef, {
                        productName: enterTitle,
                        shortDesc: enterShortDesc,
                        arrivage: enterNCategory,
                        category: enterCategory,
                        price: enterPrice,
                        gros: enterGros,
                        qty: enterQty,
                        aprice: enterAPrice,
                        Date: serverTimestamp(),
                        imgUrl: downloadURL,
                    });
                });
                toast.success('Produit enregistré');
                navigate('/dashboard/all-products');
            });
        } catch (error) {
            setLoading(false);
            toast.error('Produit non ajouté');
        }
    };

    return (
        <section>
            <Container>
                <Row>
                    <Col lg='12'>
                        {loading ? (
                            <h4 className='py-5'>Loading........</h4>
                        ) : (
                            <>
                                <h4 className='mb-5 mt-5'>Ajouter produit</h4>


                                <Form onSubmit={addProduct}>

                                    <div className='d-flex align-items-centerb justify-content-between gap-5 '>

                                        <FormGroup className="form__group w-50">
                                            <span>Image du Produit</span>
                                            <input type="file"
                                                onChange={(e) => setEnterProductImg(e.target.files[0])}
                                                required />
                                        </FormGroup>



                                        <FormGroup className="form__group w-50">
                                            <span>Nom du Produit</span>
                                            <input
                                                type="text"
                                                placeholder='mon du produit'
                                                value={enterTitle}
                                                onChange={e => setEnterTitle(e.target.value)}
                                                required />
                                        </FormGroup>
                                    </div>

                                    <div className='d-flex align-items-centerb justify-content-between gap-5 '>
                                        <FormGroup className="form__group w-50">
                                            <span>Prix</span>
                                            <input
                                                type="number"
                                                placeholder='CFA'
                                                value={enterPrice}
                                                onChange={e => setEnterPrice(e.target.value)} required />
                                        </FormGroup>

                                        <FormGroup className="form__group w-50">
                                            <span>Ancien Prix</span>
                                            <input
                                                type="number"
                                                placeholder='CFA'
                                                value={enterAPrice}
                                                onChange={e => setEnterAPrice(e.target.value)} />
                                        </FormGroup>


                                    </div>

                                    <FormGroup className="form__group ">
                                        <span>Details</span>
                                        <textarea
                                            type="text" placeholder='details....'
                                            value={enterShortDesc}
                                            onChange={e => setEnterSortDes(e.target.value)}
                                            required />
                                    </FormGroup>

                                    <div className='d-flex align-items-centerb justify-content-between gap-5 '>

                                        <FormGroup className="form__group w-50">
                                            <span>Categories</span>
                                            <select
                                                className='w-100 p-2'
                                                value={enterCategory}
                                                onChange={e => setEnterCategory(e.target.value)}   >
                                                <option >Selectionner categorie</option>
                                                <option value="t-shirt">T-Shirt</option>
                                                <option value="pulls">Pulls</option>
                                                <option value="chemise">Chemise</option>
                                                <option value="short">Short</option>
                                                <option value="ensemble">Ensemble</option>
                                                <option value="pantalon">Pantalon</option>
                                                <option value="sacs">Sacs</option>
                                                <option value="chaussure">Chaussure</option>
                                                <option value="tapete">Tapete</option>
                                                <option value="casquette">Casquette</option>
                                                <option value="electronique">Electronique</option>
                                            </select>
                                        </FormGroup>

                                        <FormGroup className="form__group w-50">
                                            <span>Nouvelle Arrivage</span>
                                            <select
                                                className='w-100 p-2'
                                                value={enterNCategory}
                                                onChange={e => setEnterNCategory(e.target.value)}   >
                                                <option >Nouvelle Arrivage</option>
                                                <option value="t-shirt">T-Shirt</option>
                                                <option value="pulls">Pulls</option>
                                                <option value="chemise">Chemise</option>
                                                <option value="short">Short</option>
                                                <option value="ensemble">Ensemble</option>
                                                <option value="pantalon">Pantalon</option>
                                                <option value="sacs">Sacs</option>
                                                <option value="chaussure">Chaussure</option>
                                                <option value="casquette">Casquette</option>
                                                <option value="electronique">Electronique</option>
                                                <option value="tapete">Tapete</option>
                                            </select>
                                        </FormGroup>

                                    </div>


                                    <div className='d-flex align-items-centerb justify-content-between gap-5 '>

                                        <FormGroup className="form__group w-50">
                                            <span>Quantité</span>
                                            <input
                                                type="number"
                                                placeholder='qty'
                                                value={enterQty}
                                                onChange={e => setEnterQty(e.target.value)} />
                                        </FormGroup>


                                        <FormGroup className="form__group w-50">
                                            <span>Vente en gros </span>
                                            <select
                                                className='w-100 p-2'
                                                value={enterGros}
                                                onChange={e => setEnterGros(e.target.value)}   >
                                                <option >Selectionner</option>
                                                <option value="t-shirt">T-Shirt</option>
                                                <option value="pulls">Pulls</option>
                                                <option value="chemise">Chemise</option>
                                                <option value="short">Short</option>
                                                <option value="ensemble">Ensemble</option>
                                                <option value="pantalon">Pantalon</option>
                                                <option value="sacs">Sacs</option>
                                                <option value="chaussure">Chaussure</option>
                                                <option value="casquette">Casquette</option>
                                                <option value="electronique">Electronique</option>
                                                <option value="tapete">Tapete</option>
                                            </select>
                                        </FormGroup>

                                    </div>


                                    <button className="buy__btn " type='submit'> Ajouter</button>
                                </Form>


                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default AddProducts;
