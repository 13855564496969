import React from 'react'
import '../styles/cart.css';
import Helmet from '../components/Helmet/Helmet'
import CommoSection from '../components/UI/CommoSection';

import { Container, Row, Col } from 'reactstrap';
import { motion } from 'framer-motion';
import { cartActions } from '../redux/slices/cartSlice';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import {  toast } from 'react-toastify';


const Cart = () => {

  const cartItems = useSelector(state => state.cart.cartItems);

  const totalAmount = useSelector(state=> state.cart.totalAmount); 

  const navigate = useNavigate()
  const dispatch = useDispatch();

  const goToCheckout = () => {
    // Stockez les données du panier dans Redux avant de naviguer vers Checkout.
    dispatch(cartActions.setCartItems(cartItems));
    navigate('/checkout');
  };

  return (
    <Helmet title='Cart' >
      <CommoSection title='Panier' />
      <section className="cart-item  mt-5">
        <Container>
          <Row>
            <Col lg='9'  >
            {
              cartItems.length === 0 ? (
                <h5 className='fs-4 text-center mt-5'>Aucune produit dans le panier</h5>
              
                ) : (
            <div className="table-responsive mt-2">
                <table className='table bordered'>
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Nom_produit</th>
                    <th>Prix</th>
                    <th>Qauntité</th>
                    <th>Supprimer</th>
                  </tr>
                </thead>

                <tbody>
                 {
                  cartItems.map((item,index)=>(
                    <Tr item={item} key={index} />
                  ))
                 }
                </tbody>
              </table>
            </div>  
              )}
            </Col>

            <Col lg='3' className='card '>
              <div className='d-flex align-items-center total gap-2'>
                <h5>Total : </h5>
                <span className='fs-4 fw-bold'>  {totalAmount} Fcfa</span>
              </div>
              <p className='fs-6 mt-2 mb-3'>Le prix d' achat sans la Livraison</p>
              <div>
                <button className='buy__btn w-100'><Link to='/checkout' >Acheter</Link> </button>

                <button className='buy__btn w-100 mb-2'><Link to='/shop' >Continuer l ' Achat</Link> </button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  )
}

const Tr = ({item})=>{

  const dispatch = useDispatch()

  const addToCart = () => {
    dispatch(cartActions.addItem({
      id: item.id,
      productName: item.productName,
      price: item.price,
      imgUrl: item.imgUrl,
    }));

    
  toast.success('Produit ajouter avec success')
};


const suppToCart = () => {
  dispatch(cartActions.suppItem({
    id: item.id,
    productName: item.productName,
    price: item.price,
    imgUrl: item.imgUrl,
  }));

  
toast.error('Produit  a été reduit  avec success')
};

  const deleteProduct = ()=>{
    dispatch(cartActions.deleteItem(item.id))

    toast.error('Produit supprimer avec success')
  }

  return  <tr  >
  <td> <img src={item.imgUrl} alt="" /> </td>
  <td>{item.productName} </td>
  <td>{item.price} Fcfa </td>
  <td>

  <div class="btn-group" role="group" aria-label="Basic mixed styles example">
            
            <button type="button" class="btn btn-success" onClick={addToCart}>+</button>
            <button type="button" class="btn "  >{item.quantity}</button>
            <button type="button" class="btn btn-danger"  onClick={suppToCart}>-</button>
          </div>
  </td>
  <td>
    <motion.i 
     whileTap={{scale: 1.2}}
     onClick={deleteProduct}
      class="ri-delete-bin-line">

      </motion.i>
    </td>
</tr>
}

export default Cart
