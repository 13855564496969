
import { Route, Routes, Navigate} from 'react-router-dom'


import Home from '../pages/Home'
import Shop from '../pages/Shop'
import Login from '../pages/Login'
import Signup from '../pages/Signup'
import ProductDetails from '../pages/ProductDetails'
import ProtectedRoute from './ProtectedRoute'

import AddProducts from '../admin/AddProducts'
import AllProducts from '../admin/AllProducts'
import Users from '../admin/Users'
import Produitaffiche from '../pages/produitaffiche'
import Search from '../components/Header/Search'
import Dashboard from '../admin/Dashboard'
import Produitgros from '../pages/produitgros'
import ProductDetailsGros from '../pages/ProductDetailsGros'

import Checkout from '../pages/checkout'
import Cart from '../pages/cart'
import CommandProduits from '../pages/commandproduits'
import ListesCommandes from '../admin/ListesCommades'
import DetailCommand from '../admin/detailcommand'
import Commandedetail from '../pages/commandedetail'
import NotFound from '../pages/NotFound'
import EditProducts from '../admin/editprod'


const Routers = () => {
  return (
   
      <Routes>
         <Route path="*" element={<NotFound />} /> 
        <Route path='/' element={<Navigate to='home' />} />
        <Route path='home' element={<Home />} />
        <Route path='shop' element={<Shop />} />
        <Route path='produitgros' element={<Produitgros />} />
        <Route path='produitaffiche' element={<Produitaffiche />} />
        <Route path='shop/:id' element={<ProductDetails />} />
        <Route path='shopgros/:id' element={<ProductDetailsGros />} />
        <Route path='Cart' element={<Cart />} />


        <Route path='/*' element={<ProtectedRoute />} >

          <Route path='command' element={<CommandProduits />} />
          <Route path='detailcom/:commandeId' element={<Commandedetail />} />

          <Route path='checkout' element={<Checkout />} />
          <Route path='dashboard' element={< Dashboard/>} />
          <Route path='dashboard/editprod/:commandeId' element={< EditProducts/>} />
          <Route path='dashboard/detailcommand/:commandeId' element={<DetailCommand />} />
          <Route path='dashboard/all-products' element={<AllProducts />} />
          <Route path='dashboard/add-product' element={<AddProducts />} />
          <Route path='dashboard/listecommande' element={<ListesCommandes />} />
          <Route path='dashboard/users' element={<Users />} />

        </Route>

        <Route path='login' element={<Login />} />
        <Route path='signup' element={<Signup />} />
        <Route path='search' element={<Search />} />
      </Routes>
    


  )
}

export default Routers
