import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom'; 
import '../styles/command.css';
import { collectionGroup, getDocs, query, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase.config';
import { toast } from 'react-toastify';

const DetailCommand = () => {
  const { commandeId } = useParams(); 
  const [ListeProduits, setListeProduits] = useState([]);
  const [commande, setCommande] = useState(null);
  const [status, setStatus] = useState(''); 

  useEffect(() => {
    const fetchData = async () => {
      const produitsQuery = query(collectionGroup(db, 'ListeProduits'));
      const produitsSnapshot = await getDocs(produitsQuery);
      const produitsData = [];

      for (const produitDoc of produitsSnapshot.docs) {
        const parentCommandId = produitDoc.ref.parent.parent.id;

        if (parentCommandId === commandeId) {
          produitsData.push(produitDoc.data());
        }
      }

      setListeProduits(produitsData);
    };

    fetchData();
  }, [commandeId]);

  // Fonction pour gérer le changement de statut
  const handleStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setStatus(selectedStatus); 
  };

  // Fonction pour enregistrer la mise à jour du statut
  const handleSaveStatus = async () => {
    if (commandeId && status) {
      try {
        // Référence au document spécifique de la commande dans la collection 'ListCommand'
        const commandeDocRef = doc(db, 'ListeCommand', commandeId);

        // Mise à jour du statut dans Firestore
        await updateDoc(commandeDocRef, {
          status: status,
        });

        toast.success('Statut de la commande mis à jour avec succès');
      } catch (error) {
        toast.error('Erreur lors de la mise à jour du statut :', error);
      }
    } else {
        toast.error('Veuillez sélectionner un statut avant de sauvegarder.');
    }
  };

  return (
    <Container className='command'>
      <Row>
        <Col lg='12'>
          <div className="table-responsive mt-5">
            <table className='table bordered table_detail'>
              <thead>
                <tr>
                  <th>Listes Des Details des Commandes</th>
                </tr>
                <tr>
                  <th>Image</th>
                  <th>Nom</th>
                  <th>Quantité</th>
                  <th>Prix_U</th>
                </tr>
              </thead>
              <tbody>
                {ListeProduits.map((produit, index) => (
                  <tr key={index}>
                    <td><img src={produit.imgUrl} alt="" /></td>
                    <td>{produit.productName}</td>
                    <td>{produit.quantity}</td>
                    <td>{produit.price} Fcfa</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="mt-3 status">

              <select 
                id="statusSelect" 
                className="form-select" 
                value={status} 
                onChange={handleStatusChange} // Détecter les changements de statut
              >
                <option value="">-- Sélectionnez un statut --</option>
                <option value="Livraison">Livraison</option>
                <option value="Livre">Déjà livré</option>
                <option value="Annuler">Annuler la livraison</option>
              </select>
              <button className="btn btn-primary mt-2" onClick={handleSaveStatus}>Enregistrer</button> {/* Bouton pour enregistrer le statut */}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DetailCommand;
