import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import useGetsData from '../custom-hooks/useGetsData';
import { collectionGroup, getDocs, query } from 'firebase/firestore';
import { db } from '../firebase.config';
import useAuth from '../custom-hooks/useAuth';
import '../styles/command.css';
import CommoSection from '../components/UI/CommoSection';
import Helmet from '../components/Helmet/Helmet';

const ListesCommandes = () => {
  const { data: ListeCom } = useGetsData('ListeCommand');
  const [ListeProduits, setListeProduits] = useState([]);
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      const produitsQuery = query(collectionGroup(db, 'ListeProduits'));
      const produitsSnapshot = await getDocs(produitsQuery);
      const produitsData = [];

      for (const produitDoc of produitsSnapshot.docs) {
        const parentCommandId = produitDoc.ref.parent.parent.id;
        const parentCommandData = ListeCom.find(
          (commande) => commande.id === parentCommandId
        );

        if (parentCommandData) {
          const produit = {
            ...produitDoc.data(),
            commandInfo: parentCommandData,
          };
          produitsData.push(produit);
        }
      }

      setListeProduits(produitsData);
    };

    fetchData();
  }, [ListeCom]);

  const handleViewDetails = (commandeId) => {
    navigate(`/detailcom/${commandeId}`);
  };

  const getStatusBackgroundColor = (status) => {
    if (status === 'Livraison') {
      return 'rgb(226, 204, 9)';
    } else if (status === 'Livre') {
      return 'rgb(3, 168, 31)';
    } else if (status === 'Annuler') {
      return 'red';
    } else if (status === 'Demande') {
      return 'rgb(54, 164, 238)';
    }
    return 'transparent';
  };

  // Filtrer les commandes pour n'afficher que celles qui correspondent à l'email du currentUser
  const filteredListeCom = ListeCom.filter(
    (commande) => commande.email === currentUser?.email
  );

  // Trier les commandes par date
  const sortedListeCom = [...filteredListeCom]
    .sort((a, b) => b.date.toMillis() - a.date.toMillis())
    .slice(0, 5); // Affiche uniquement les 5 commandes les plus récentes

  return (
    <Helmet title='Commande'>
      <CommoSection title="Liste des Commandes" />
      <Container className='command'>
        <Row>
          <Col lg='12'>
            <div className="table-responsive m-5">
              <table className="table bordered custom-table">
                <thead>
                  <tr className='bg-light'>
                    <th>IdCom</th>
                    <th>Date_C</th>
                    <th>Numéro</th>
                    <th>Ville</th>
                    <th>Quartier</th>
                    <th>Lieu_L</th>
                    <th>Date_L</th>
                    <th>Heure_L</th>
                    <th>Status</th>
                    <th>Prix_Total</th>
                    <th>Produits</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedListeCom.map((commande, index) => (
                    <tr key={commande.id} className={index === 0 ? '#b8bfc7' : ''}>
                      <td>{commande.IdCommande}</td>
                      <td>{commande.date.toDate().toLocaleDateString()}</td>
                      <td>{commande.numero}</td>
                      <td>{commande.ville}</td>
                      <td>{commande.quartier}</td>
                      <td>{commande.lieu}</td>
                      <td>{commande.dateL}</td>
                      <td>{commande.heureL}</td>
                      <td style={{ backgroundColor: getStatusBackgroundColor(commande.status), color: 'white' }}>
                        {commande.status}
                      </td>
                      <td>{commande.prixTotal} Fcfa</td>
                      <td>
                        <i
                          className="ri-eye-line"
                          onClick={() => handleViewDetails(commande.id)}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Container>
    </Helmet>
  );
};

export default ListesCommandes;
