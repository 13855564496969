
import React from 'react'
import './footer.css'

import { Container, Row, Col ,ListGroup, ListGroupItem} from 'reactstrap';
import { Link } from 'react-router-dom';
import useGetsData from '../../custom-hooks/useGetsData';



const Footer = () => {

   const { data: productsData, loading  } = useGetsData('service')
  
  const year = new Date().getFullYear()

  
  const handleLinkhome = () => {
    // Recharge la page lorsque le lien est cliqué
    window.location.href = '/home' ;
};

const handleLinkshop = () => {
  // Recharge la page lorsque le lien est cliqué
  window.location.href = '/shop' ;
};

const handleLinkservice = () => {
  // Recharge la page lorsque le lien est cliqué
  window.location.href = '/service' ;
};

const handleLink = () => {
  // Recharge la page lorsque le lien est cliqué
  window.location.href = '/produitgros';
};

  return (
    <footer className='footer mt-5 '>
      <Container>
        <Row  >
          <Col lg="4" className='mb-4' md='6'>  
          <div className="logo">
            <div>
              <h1 className='text-white'>Zombuy</h1>
            </div>
          </div>
          <p className="footer__text mt-4">
             Zombuy est une platforme de vente en ligne  de prét à porter et autre
            .
            </p>
          </Col>


         

          <Col lg="4" className='mb-4' md='3'>   
          <div className="footer__quick-links">
              <h4 className="quick__links-title">Liens Utiles</h4>
              <ListGroup className='mb-3'>
                <ListGroupItem className='ps-0 border-0'>
                  <Link   onClick={handleLinkshop}>Produit</Link>
                </ListGroupItem>

                <ListGroupItem className='ps-0 border-0'>
                  <Link   onClick={handleLinkhome}>Accueil</Link>
                </ListGroupItem>


                
                <ListGroupItem className='ps-0 border-0'>
                  <Link   onClick={handleLink} >Vente En Gros</Link>
                </ListGroupItem>

        
              </ListGroup>
            </div>
          </Col>

          <Col lg="4" md='4'>   
          <div className="footer__quick-links">
              <h4 className="quick__links-title">Contact</h4>
              <ListGroup className='footrt__contact'>
                <ListGroupItem className='ps-0 border-0 d-flex align-item-center gap-2'>
                 <span><i class="ri-map-pin-line"></i></span>
                 <p>Lomégan ,Lome</p>
                </ListGroupItem>

                <ListGroupItem className='ps-0 border-0 d-flex align-item-center gap-2 '>
                <span><i class="ri-phone-line"></i></span>
                 <p>+228 92170365 </p>
                </ListGroupItem>

                <ListGroupItem className='ps-0 border-0 d-flex align-item-center gap-2'>
                  <span><i class="ri-mail-line"></i></span>
                  <p>zombuy23@gmail.com</p>
                </ListGroupItem>
              </ListGroup>
            </div>
          </Col>

          <Col lg='12'>
            <p className="footer__copyright"> Copyright {year} PhenixTec </p>
          </Col>
        </Row>
      </Container>
      
    </footer>
  )
}

export default Footer
