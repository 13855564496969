import React, { useRef, useEffect, useState } from 'react';
import './header.css';
import useAuth from '../../custom-hooks/useAuth';
import { motion } from 'framer-motion';
import { NavLink, useNavigate, useLocation, Link } from 'react-router-dom';
import logo from '../../assets/images/eco-logo2.png';
import { Container, Row, Col, Form } from 'reactstrap';
import { useSelector } from 'react-redux';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase.config';
import { toast } from 'react-toastify';
import { CgShoppingCart } from 'react-icons/cg';
import useGetsData from '../../custom-hooks/useGetsData';

const Header = () => {
  const headerRef = useRef(null);
  const location = useLocation();
  const totalQuantity = useSelector((state) => state.cart.totalQuantity);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { data: products } = useGetsData('products');
  const [searchTerm, setSearchTerm] = useState('');



  const logout = () => {
    signOut(auth)
      .then(() => {
        toast.success('Logged out');
        navigate('/home');
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleSearch = (e) => setSearchTerm(e.target.value);

  const handleSearchSubmit = () => {
    const searchedProducts = products.filter((item) =>
      item.productName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    navigate(`/search?query=${searchTerm}`, { state: { productsData: searchedProducts } });
  };



  const menuToggle = () => menuRef.current.classList.toggle('active__menu');


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const header = headerRef.current;

    if (window.scrollY > 0) {
      header.classList.add('fixed-header');
    } else {
      header.classList.remove('fixed-header');
    }
  };

  const handleLink = (url) => (e) => {
    window.location.href = url;
  };

  const getInitialAndColor = () => {
    if (!currentUser || !currentUser.email) return { initial: '', color: 'black' };
    const firstLetter = currentUser.email.charAt(0).toUpperCase();
    const color = firstLetter >= 'A' && firstLetter <= 'E' ? 'green' : 'black';
    return { initial: firstLetter, color };
  };

  const { initial, color } = getInitialAndColor();




  return (
    <header ref={headerRef}>
      <div className="contener">
        <Container className="head">
          <Row>
            <Col lg="7" className="group">
              <marquee className="text">
               <span className='promo'>Promotion, </span> <span className="liv"> Livraison gratuite sur le Prix d' Achat à partir de 5000 Fcfa et un Réduction de 10% sur le Prix d' Achat à partir de 10000 Fcfa </span>
              </marquee>
            </Col>
            <Col lg="5" className="contact d-flex gap-3">
              <div className="d-flex align-item-center gap-2">
              <i class="ri-whatsapp-fill whatsapp "></i>
                <span>+228 92170365</span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <nav className="navbar d-flex align-items-center">
        <div className="container d-flex align-items-center justify-content-between">
          <div className="logo">
            <Link to="/home">
              <img src={logo} alt="logo" />
              </Link>
              <Link to="/home">
            <h1>Zombuy Shop</h1>
            </Link>
          </div>


          <div className="search">
            <div className="input-group">
              <input
                type="text"
                className="input"
                placeholder="Search produits"
                value={searchTerm}
                onChange={handleSearch}
              />
              <button className="add-search" id="basic-addon2" onClick={handleSearchSubmit}>
                Search
              </button>
            </div>
          </div>

          <div className="navigation" ref={menuRef} onClick={menuToggle}>
            <ul className="menu d-flex nav-links">
              <Link to="/home" className={location.pathname === '/home' ? 'active' : 'inactive'}>
                Accueil
              </Link>
              <Link to="/shop" className={location.pathname === '/shop' ? 'active' : 'inactive'}>
                Produit
              </Link>

             
              <Link to={'cart'} className={location.pathname === '/cart' ? 'active' : 'inactive'}>
                <div className="d-flex ">
                  <span><CgShoppingCart className='fs-3 mx-2' /></span>
                  <p>Panier
                    <span className="panier">
                      {totalQuantity > 0 && `(${totalQuantity})`}
                    </span>
                  </p>
                </div>

              </Link>

              {currentUser ? (
                <NavLink  >
                  <div className="d-flex">
                    <div className="dropdown card-0">
                      <a onClick={menuToggle} id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="user-initial" style={{ backgroundColor: color }}>{initial}</span>
                      </a>

                      <ul className="dropdown-menu drop__Link " aria-labelledby="dropdownMenuLink">
                       
                        <span>{currentUser.email} </span>
                        <li className="btn_Link dropdown-item">
                          <Link onClick={handleLink('/command')} className={location.pathname === '/siteweb' ? 'active' : 'inactive'}>
                            Dashboard
                          </Link>
                        </li>
                        <li className="btn_Link dropdown-item">
                          <Link onClick={logout} className={location.pathname === '/application-mobile' ? 'active' : 'inactive'}>
                            Déconnexion
                          </Link>
                        </li>

                      </ul>
                    </div>

                  </div>
                </NavLink>
              ) : (
                <Link to={'login'} className={location.pathname === '/signup' ? 'active' : 'inactive'}>Connexion </Link>
              )}
            </ul>
          </div>

          <div className="nav__icons">
            <div className="mobile__menu">
              <span onClick={menuToggle}>
                <i className="ri-menu-line"></i>
              </span>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
