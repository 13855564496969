import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom'; 
import '../styles/command.css';
import { collectionGroup, getDocs, query, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase.config';
import { toast } from 'react-toastify';
import CommoSection from '../components/UI/CommoSection';
import Helmet from '../components/Helmet/Helmet';


const Commandedetail = () => {
  const { commandeId } = useParams(); 
  const [ListeProduits, setListeProduits] = useState([]);
  const [commande, setCommande] = useState(null);
  const [status, setStatus] = useState(''); 

  useEffect(() => {
    const fetchData = async () => {
      const produitsQuery = query(collectionGroup(db, 'ListeProduits'));
      const produitsSnapshot = await getDocs(produitsQuery);
      const produitsData = [];

      for (const produitDoc of produitsSnapshot.docs) {
        const parentCommandId = produitDoc.ref.parent.parent.id;

        if (parentCommandId === commandeId) {
          produitsData.push(produitDoc.data());
        }
      }

      setListeProduits(produitsData);
    };

    fetchData();
  }, [commandeId]);

 

  return (
    <Helmet title='Detail_Commande'>
      <CommoSection title="Detail de la Commande" />
    <Container className='command'>
      <Row>
        <Col lg='12'>
          <div className="table-responsive mt-5">
            <table className='table bordered table_detail'>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Nom</th>
                  <th>Quantité</th>
                  <th>Prix_U</th>
                </tr>
              </thead>
              <tbody>
                {ListeProduits.map((produit, index) => (
                  <tr key={index}>
                    <td><img src={produit.imgUrl} alt="" /></td>
                    <td>{produit.productName}</td>
                    <td>{produit.quantity}</td>
                    <td>{produit.price} Fcfa</td>
                  </tr>
                ))}
              </tbody>
            </table>
        
          </div>
        </Col>
      </Row>
    </Container>
    </Helmet>
 
);
};

export default Commandedetail;
