import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import useGetsData from '../custom-hooks/useGetsData';
import { db } from '../firebase.config';
import { doc, deleteDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import '../styles/dashboard.css';

const AllProducts = () => {
  const { data: products, loading } = useGetsData('products');
  const [productsData, setProductsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 10;
  const navigate = useNavigate();

  // Trier les produits par date (récent en premier) et appliquer la recherche
  useEffect(() => {
    const searchedProducts = products
      .filter((item) =>
        item.productName.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => new Date(b.Date) - new Date(a.Date)); // Trier par date de création (récent en premier)
    
    setProductsData(searchedProducts);
  }, [products, searchTerm]);

  // Gérer la recherche
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Supprimer un produit
  const deleteProduct = async (id) => {
    await deleteDoc(doc(db, 'products', id));
    window.location.reload(); // Recharge la page après la suppression
    toast.success('Suppression effectuée !');
  };

  // Rediriger vers les détails du produit
  const handleViewDetails = (commandeId) => {
    navigate(`/dashboard/editprod/${commandeId}`);
  };

  // Pagination : calculer les produits à afficher pour la page actuelle
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = productsData.slice(indexOfFirstProduct, indexOfLastProduct);

  // Changer de page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <section>
        <div className='dash p-5'>
          <div className="products__box m-2">
            <h5>Nombres Totals</h5>
            <span>{products.length}</span>
          </div>
        </div>
      </section>

      <Container>
        <div className='search text-center mb-2'>
          <div className="search__box">
            <input
              type="text"
              placeholder="Rechercher..."
              onChange={handleSearch}
            />
            <span>
              <i className="ri-search-line"></i>
            </span>
          </div>
        </div>
        <Row>
          <Col lg='12'>
            <div className="table-responsive mt-2">
              <table className='table bordered'>
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Titre</th>
                    <th>Categorie</th>
                    <th>Arrivage</th>
                    <th>Gros</th>
                    <th>Prix</th>
                    <th>Ancien_Prix</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <h3 className='py-5 fw-bold'>loading....</h3>
                  ) : (
                    currentProducts.map(item => (
                      <tr key={item.id}>
                        <td><img src={item.imgUrl} alt="" className="allimage" /> </td>
                        <td>{item.productName}</td>
                        <td>{item.category}</td>
                        <td>{item.arrivage}</td>
                        <td>{item.gros}</td>
                        <td>{item.price} CFA</td>
                        <td>{item.aprice} CFA</td>
                        <td>{item.shortDesc}</td>
                        <td className='gap-3'>
                          <button onClick={() => deleteProduct(item.id)} className="btn btn-danger">Delete</button>
                          <i className="ri-eye-line" onClick={() => handleViewDetails(item.id)}></i>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              {/* Pagination */}
              <nav>
                <ul className='pagination justify-content-center'>
                  {Array.from({ length: Math.ceil(productsData.length / productsPerPage) }, (_, index) => (
                    <li key={index} className='page-item'>
                      <button onClick={() => paginate(index + 1)} className='page-link'>
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AllProducts;
