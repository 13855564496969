import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import ProductsCard from './ProductsCard';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const ProductsList = ({ data }) => {
  const swiperRef = useRef(null);

  return (
    <Swiper
      ref={swiperRef}
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
      slidesPerView={1}
      breakpoints={{
        360: { slidesPerView: 1, spaceBetween: 10 },
        450: { slidesPerView: 2, spaceBetween: 10 },
        768: { slidesPerView: 3, spaceBetween: 10 },
        1024: { slidesPerView: 4, spaceBetween: 40 },
      }}
      
      pagination={{ clickable: true }}
      autoplay={{ delay: 1500, disableOnInteraction: false }}
      onSwiper={(swiper) => (swiperRef.current = swiper)}
      onSlideChange={() => console.log('Slide changed')}
    >
      {data.map((item, index) => (
        <SwiperSlide key={index}
          onMouseEnter={() => swiperRef.current.autoplay.stop()}
          onMouseLeave={() => swiperRef.current.autoplay.start()}
        >
          <ProductsCard item={item} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ProductsList;
