import React, { useState, useRef, useEffect } from 'react'

import { Container, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';

import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { cartActions } from '../redux/slices/cartSlice';
import { toast } from 'react-toastify';


import Helmet from '../components/Helmet/Helmet';
import CommoSection from '../components/UI/CommoSection';
import '../styles/product-details.css';
import '../styles/shop.css';
import ProductsList from '../components/UI/ProductsList';

import { db } from '../firebase.config';
import { doc, getDoc } from 'firebase/firestore';
import useGetsData from '../custom-hooks/useGetsData';

const ProductDetails = () => {

  const quantity = useSelector(state => state.cart.totalQuantity)

  const [product, setProduct] = useState({})

  const [tab, setTab] = useState('desc')
  const reviewUser = useRef("")
  const reviewMsg = useRef("")
  const dispatch = useDispatch()

  const [rating, setRating] = useState(null)
  const { id } = useParams()

  const { data: products } = useGetsData('products')

  const docRef = doc(db, 'products', id)

  useEffect(() => {
    const getProduct = async () => {
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        setProduct(docSnap.data())
      } else {
        console.log('no product')
      }
    }
    getProduct()
  }, [])

  const { imgUrl,
    productName,
    price,
    aprice,
    shortDesc,
    category

  } = product;

  const relatedProducts = products.filter(item => item.category === category);



  useEffect(() => {
    window.scrollTo(0, 0)
  }, [product])


  const addToCart = () => {
    dispatch(cartActions.addItem({
      id,
      imgUrl,
      productName,
      price,
    })
    );
    toast.success('Produit a été ajouté  avec success')
  };

  const suppToCart = () => {
    dispatch(cartActions.suppItem({
      id,
      imgUrl,
      productName,
      price,
    })
    );
    toast.error('Produit a été supprimer  avec success')
  };



  return (
    <Helmet title={productName}>
      <CommoSection title={productName} />

      <section className="pt-0">
        <Container>
          <Row>
            <Col lg="6" className='p-5 img-detail'>
              <img src={imgUrl} alt="" className=' imgprod ' />
            </Col>

            <Col lg="6">
              <div className="product__details">
                <h2>{productName}</h2>

                <div className='d-flex align-items-center gap-5 '>
                  <span className='product__price'>{price} Fcfa </span>
                  <span className='price'> <strike>{aprice} Fcfa</strike></span>
                  
                </div>

                <p className='product__desc mt-4 mb-4'>{shortDesc} </p>

                <div class="btn-group mb-4" role="group" aria-label="Basic mixed styles example">

                  <button type="button" class="btn btn-success" onClick={addToCart}>+</button>
                  <button type="button" class="btn "  >{quantity}</button>
                  <button type="button" class="btn btn-danger" onClick={suppToCart}>-</button>
                </div>

                <div className="  what ">
                  
                  <a href="https://wa.me/+22892170365">
                    <motion.button className="add "
                      whileTap={{ scale: 1.2 }}

                    >
                      <i class="ri-whatsapp-fill what "></i>

                    </motion.button>
                  </a>
                </div>

              </div>
            </Col>
          </Row>
        </Container>
      </section>


      <section className="shop-products p-5">
        <div className="container-xxl">
          <div className="row">
            <Col lg='12' className='text-center mb-5'>
              <h2 className='section__title'>Voir produits de se  categorie</h2>
            </Col>
            <ProductsList data={relatedProducts} />
          </div>
        </div>
      </section>
    </Helmet>
  )
}

export default ProductDetails
