import React, { useState } from 'react';
import { Container, Row, Col, Form, FormGroup } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import CommoSection from '../components/UI/CommoSection';
import Helmet from '../components/Helmet/Helmet';
import { toast } from 'react-toastify';
import { db } from '../firebase.config';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import '../styles/checkout.css';
import { cartActions } from '../redux/slices/cartSlice';
import useAuth from '../custom-hooks/useAuth';

const Checkout = () => {
  const { currentUser } = useAuth();
  const totalQty = useSelector((state) => state.cart.totalQuantity);
  let totalAmount = useSelector((state) => state.cart.totalAmount);
  let total = useSelector((state) => state.cart.totalAmount); // Pas en const pour la réduction
  const cartItems = useSelector((state) => state.cart.cartItems);

  const [nom, setNom] = useState('');
  const [numero, setNumero] = useState('');
  const [ville, setVille] = useState('');
  const [quartier, setQuartier] = useState('');
  const [lieu, setLieu] = useState('');
  const [dateL, setDateL] = useState('');
  const [heureL, setHeureL] = useState('');
  let reduction = 0; // Correction du nom en camelCase

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const viderPanier = () => {
    dispatch(cartActions.viderPanier());
  };

  const generateId = () => {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let randomLetters = '';
    for (let i = 0; i < 3; i++) {
      randomLetters += letters.charAt(Math.floor(Math.random() * letters.length));
    }
    const randomNumbers = Math.floor(100 + Math.random() * 900);
    return randomLetters + randomNumbers;
  };

  // Calcul de la réduction
  if (totalAmount >= 10000) {
    reduction = (totalAmount * 10) / 100;
    totalAmount = totalAmount - reduction; // Applique la réduction sur le montant total
  }

  const handleCheckout = async () => {
    try {
      const docRef = await addDoc(collection(db, 'ListeCommand'), {
        IdCommande: generateId(),
        nom,
        numero,
        ville,
        quartier,
        lieu,
        status: 'Demande',
        dateL,
        heureL,
        email: currentUser.email,
        prixTotal: totalAmount,
        date: serverTimestamp(),
      });

      const commandId = docRef.id;
      for (const item of cartItems) {
        const productData = {
          id: item.id || '',
          productName: item.productName || '',
          price: item.price || 0,
          aprice: item.aprice || 0,
          imgUrl: item.imgUrl || '',
          category: item.category || '',
          quantity: item.quantity || 1,
        };

        await addDoc(collection(db, 'ListeCommand', commandId, 'ListeProduits'), productData);
      }

      viderPanier();
      toast.success('Commande enregistrée avec succès');
      navigate('/command');
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement de la commande', error);
      toast.error('Erreur lors de la commande');
    }
  };

  return (
    <Helmet title="Achat">
      <CommoSection title="Achat" />

      <section className="cart-item">
        <Container>
          <Row>
            <Col>
              {cartItems.length === 0 ? (
                <h5 className="fs-4  mb-5 mt-5 text-center">Aucun produit ajouté</h5>
              ) : (
                <div className="table-responsive mt-1">
                  <table className="table bordered">
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Nom du produit</th>
                        <th>Quantité</th>
                        <th>Prix Unitaire</th>
                      </tr>
                    </thead>

                    <tbody>
                      {cartItems.map((item) => (
                        <tr key={item.id}>
                          <td><img src={item.imgUrl} alt="" /></td>
                          <td>{item.productName}</td>
                          <td>{item.quantity}</td>
                          <td>{item.price} Fcfa</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>

      <section >
        <Container>
          <Row>
            <Col lg="7">
              <span className=" check_out">Ajouter les informations sur la Lieu et le Jour et Heure de Livraison</span>

              <Form className="billing__form mt-3">
                <FormGroup className="form__group">
                  <input
                    type="text"
                    placeholder="Entrer votre nom"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                  />
                </FormGroup>
                <FormGroup className="form__group">
                  <input
                    type="number"
                    placeholder="Entrer votre contact"
                    value={numero}
                    onChange={(e) => setNumero(e.target.value)}
                  />
                </FormGroup>
                <FormGroup className="form__group">
                  <input
                    type="text"
                    placeholder="Entrer votre ville de résidence"
                    value={ville}
                    onChange={(e) => setVille(e.target.value)}
                  />
                </FormGroup>
                <FormGroup className="form__group">
                  <input
                    type="text"
                    placeholder="Entrer votre quartier"
                    value={quartier}
                    onChange={(e) => setQuartier(e.target.value)}
                  />
                </FormGroup>
                <FormGroup className="form__group">
                  <input
                    type="text"
                    placeholder="Préciser le lieu de livraison"
                    value={lieu}
                    onChange={(e) => setLieu(e.target.value)}
                  />
                </FormGroup>

                <div className="d-flex align-items-center justify-content-between gap-5">
                  <FormGroup className="form__group w-50">
                    <input
                      type="date"
                      value={dateL}
                      onChange={(e) => setDateL(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="form__group w-50">
                    <input
                      type="time"
                      value={heureL}
                      onChange={(e) => setHeureL(e.target.value)}
                    />
                  </FormGroup>
                </div>
              </Form>
            </Col>

            <Col lg="5">
              <div className="checkout__cart card">
                <h5>Total Qty : <span>{totalQty}</span></h5>

                <h5> Livraison : 
                  <span>
                    {totalAmount >= 5000 ? 'gratuite' : 'Payante'}
                  </span>
                </h5>
                <h5>Prix Total : <span className="total">{total} Fcfa</span></h5>
                <h5> Réduction : 
                  <span>
                    {reduction > 0 ? `${reduction} Fcfa` : 'Pas de réduction'}
                  </span>
                </h5>

                <h4>Montant à Payer : <span className="total">{totalAmount} Fcfa</span></h4>

                <motion.button
                  whileTap={{ scale: 1.2 }}
                  className="check__btn  w-100"
                  onClick={handleCheckout}
                >
                  Commander
                </motion.button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Checkout;
