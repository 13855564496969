import React, { useRef } from 'react'
import { Container, Row } from 'reactstrap';

import useAuth from '../custom-hooks/useAuth';
import '../styles/admin-nav.css';
import { Link } from 'react-router-dom';
import { auth } from '../firebase.config';
import { signOut } from "firebase/auth";
import { toast } from 'react-toastify';

import { VscAccount } from 'react-icons/vsc'

import { NavLink,useNavigate, useLocation } from 'react-router-dom';


const admin__nav = [
  {
    display: 'Tableau de Bord',
    path: '/dashboard'
  },  
  {
    display: 'Listes des Commande',
    path: '/dashboard/listecommande'
  },
  {
    display: 'Listes des Produits',
    path: '/dashboard/all-products'
  },
  {
    display: 'Ajouter un Produit',
    path: '/dashboard/add-product'
  }
 
  
 


]





const AdminNavs = () => {

  const headerRef = useRef(null);

  const menuRef = useRef(null);

  const { currentUser } = useAuth()

  const location = useLocation();
  const navigate = useNavigate()

  const logout = () => {

    signOut(auth).then(() => {
      toast.success('Logged out')
      navigate('/home')
    }).catch(err => {
      toast.error(err.message)
    })
  }

  const menuToggle = () => menuRef.current.classList.toggle('active__menu');

  return (
    <>
      <header ref={headerRef} className='admin__header'>
       
          <Container>
            <div className="admin__nav-warpper-top">
              <div className="logo">
              <Link to='/dashboard' >
                <h2>Tableau  de Bord</h2>
                </Link> 
              </div>

              <div className="util pt-2">
              <NavLink  >
                  <div className="d-flex decon">
                    <div className=" Navlink">
                    
                      <ul >
                        <li><a  >
                         
                            <div className="d-flex">
                              {currentUser ? (
                                <p onClick={logout}>Deconnexion</p>
                              ) : (
                                <p>Connexion</p>
                              )}

                            </div>
                          
                        </a></li>
                        
                      </ul>
                    </div>

                  </div>
                </NavLink>
              </div>
              
            </div>
          </Container>
        
      </header>

      <section className="admin__menu p-0 ">
        <Container>
          <Row>
            <div className="admin__navigation navigation" ref={menuRef} onClick={menuToggle}>
              <ul className="admin__menu-list menu">
                {
                  admin__nav.map((item, index) => (
                    <li className="admin__menu-item" key={index}>
                      <NavLink to={item.path} className={navclass =>
                        navclass.isActive ? 'active__admin-menu' : ''}>{item.display} </NavLink>
                    </li>
                  ))
                }
              </ul>
            </div>

            <div className="nav__icons">

              <div className="mobile__menu">
                <span onClick={menuToggle}>
                  <i class="ri-menu-line"></i></span>
              </div>
            </div>


          </Row>
        </Container>
      </section>
    </>
  )

}

export default AdminNavs
