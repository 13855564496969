import React from 'react'
import { Container, Row, Col } from 'reactstrap';
import '../styles/dashboard.css'
import { Link } from 'react-router-dom';
import useGetsData from '../custom-hooks/useGetsData';

const Dashboard = () => {

  const {data: products } = useGetsData('products')
  const {data: users } = useGetsData('users')

  return (
   <>
   <section> 
      <div className='dash p-5 '>
      
          <div className="products__box m-2 ">
        <Link to='/dashboard/all-products' >
            <h5>Total produits</h5>
            <span>{products.length} </span>
        </Link>    
          </div>
       
          <div className="users__box m-2 ">
          <Link to='/dashboard/users' >
            <h5>Utulisateurs</h5>
            <span>{users.length} </span>
          </Link>  
          </div>
       
      </div>
   </section>
   </>
  )
}

export default Dashboard
