import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom'; // Importer useNavigate
import '../styles/listcommand.css';
import useGetsData from '../custom-hooks/useGetsData';
import { collectionGroup, getDocs, query } from 'firebase/firestore';
import { db } from '../firebase.config';
import { color } from 'framer-motion';

const ListesCommandes = () => {
  const { data: ListeCom } = useGetsData('ListeCommand');
  const [ListeProduits, setListeProduits] = useState([]);
  const [statusSums, setStatusSums] = useState({Demande: 0 , Livraison: 0, Livre: 0, Annuler: 0 });
  const navigate = useNavigate(); // Utiliser useNavigate
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      const produitsQuery = query(collectionGroup(db, 'ListeProduits'));
      const produitsSnapshot = await getDocs(produitsQuery);
      const produitsData = [];

      for (const produitDoc of produitsSnapshot.docs) {
        const parentCommandId = produitDoc.ref.parent.parent.id;
        const parentCommandData = ListeCom.find(
          (commande) => commande.id === parentCommandId
        );

        if (parentCommandData) {
          const produit = {
            ...produitDoc.data(),
            commandInfo: parentCommandData,
          };
          produitsData.push(produit);
        }
      }

      setListeProduits(produitsData);
    };

    fetchData();
  }, [ListeCom]);

  useEffect(() => {
    // Calculer la somme des statuts par catégorie
    const statusCounts = ListeCom.reduce((acc, commande) => {
      if (commande.status === 'Livraison') {
        acc.Livraison += 1;
      }else if (commande.status === 'Demande') {
        acc.Demande += 1;
      }
       else if (commande.status === 'Livre') {
        acc.Livre += 1;
      } else if (commande.status === 'Annuler') {
        acc.Annuler += 1;
      }
      return acc;
    }, { Livraison: 0, Livre: 0, Annuler: 0 ,Demande:0});

    setStatusSums(statusCounts);
  }, [ListeCom]);

  const handleViewDetails = (commandeId) => {
    navigate(`/dashboard/detailcommand/${commandeId}`); // Utiliser navigate pour rediriger vers la page de détails de la commande
  };


  const getStatusBackgroundColor = (status) => {
    if (status === 'Livraison') {
      return 'rgb(226, 204, 9)';
    } else if (status === 'Livre') {
      return 'rgb(3, 168, 31)';
    } else if (status === 'Annuler') {
      return 'red';
    } else if (status === 'Demande') {
        return 'rgb(54, 164, 238)';
      }
    return 'transparent';
  };

  const sortedListeCom = [...ListeCom].sort((a, b) => b.date.toMillis() - a.date.toMillis());

  // Pagination : calculer les produits à afficher pour la page actuelle
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = sortedListeCom.slice(indexOfFirstProduct, indexOfLastProduct);

  // Changer de page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  
  return (
    <>
    <section> 
      <div className='dash p-5'>
      <div className="Dem__box m-2">
          <h5>Demande de Produit</h5>
          <h1>{statusSums.Demande}</h1> {/* Afficher la somme */}
        </div>
       
        <div className="Liv__box m-2">
          <h5>En Cour Livraison</h5>
          <h1>{statusSums.Livraison}</h1> {/* Afficher la somme */}
        </div>
       
        <div className="Livré__box m-2">
          <h5> Déja Livré</h5>
          <h1>{statusSums.Livre}</h1> {/* Afficher la somme */}
        </div>
       
        <div className="Annuler__box m-2">
          <h5>Annuler</h5>
          <h1>{statusSums.Annuler}</h1> {/* Afficher la somme */}
        </div>
      </div>
    </section>
   
   <Container className='command'>
    <Row>
      <Col lg='12'>
    
          <div className="table-responsive m-5 ">
            <table className="table bordered custom-table ">
              <thead>
                <tr className='bg-light'>
                  <th>Email</th>
                  <th>Nom</th>
                  <th>IdCom</th>
                  <th>Date_C</th>
                  <th>Numéro</th>
                  <th>Ville</th>
                  <th>Quartier</th>
                  <th>Lieu_L</th>
                  <th>Date_L</th>
                  <th>Heure_L</th>
                  <th>Status</th>
                  <th>Prix_Total</th>
                  <th>Produits</th>
                </tr>
              </thead>
              <tbody>
                {currentProducts.map((commande, index) => (
                  <tr key={commande.id} className={index === 0 ? '#b8bfc7' : ''}>
                    <td>{commande.email}</td>
                    <td>{commande.nom}</td>
                    <td>{commande.IdCommande}</td>
                    <td>{commande.date.toDate().toLocaleDateString()}</td>
                    <td>{commande.numero}</td>
                    <td>{commande.ville}</td>
                    <td>{commande.quartier}</td>
                    <td>{commande.lieu}</td>
                    <td>{commande.dateL}</td>
                    <td>{commande.heureL}</td>
                    <td style={{ backgroundColor: getStatusBackgroundColor(commande.status),color:'white', } }>
                      {commande.status}
                    </td>
                    <td style={{color:'#e74b0e',fontWeight:'500'}}>{commande.prixTotal} Fcfa</td>
                    <td>
                      <i
                        className="ri-eye-line"
                        onClick={() => handleViewDetails(commande.id)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <nav>
                <ul className='pagination justify-content-center mt-4'>
                  {Array.from({ length: Math.ceil(sortedListeCom.length / productsPerPage) }, (_, index) => (
                    <li key={index} className='page-item'>
                      <button onClick={() => paginate(index + 1)} className='page-link'>
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
              
          </div>
          </Col>
      </Row>
    </Container>
    </>
  );
};

export default ListesCommandes;
