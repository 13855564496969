import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import useGetsData from '../custom-hooks/useGetsData';
import { db } from '../firebase.config';
import { doc, deleteDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';

const Users = () => {

    const { data: usersData, loading  } = useGetsData('users');

    const deleteUser = async(id) => {
        await deleteDoc(doc(db, 'users', id));
        toast.success('Suppression effectuée !');
    }

    const formatDate = (dateString) => {
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          timeZoneName: 'short'
        };
        const date = new Date(dateString?.seconds * 1000);  // Firebase timestamp handling
        return date.toLocaleDateString('fr-FR', options);
    };

    return (
        <Container>
            <Row>
                <Col lg='12' className='mt-5'>
                    <h2>Listes des Utilisateurs</h2>
                </Col>
                <Col lg='12' className='pt-5'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Email</th>
                                <th>Téléphone</th>
                                <th>Date de création</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ? <h5 className='pt-5 fw-bold'>Chargement...</h5>
                                : usersData?.map(user => (
                                    <tr key={user.uid}>
                                        <td>{user.FirstName}</td>
                                        <td>{user.email}</td>
                                        <td>{user.Phone}</td>
                                        <td>{formatDate(user.Date)}</td>
                                        <td>
                                            <button onClick={() => { deleteUser(user.uid) }} className="btn btn-danger">
                                                Supprimer
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </Col>
            </Row>
        </Container>
    );
}

export default Users;
